import React from 'react';
import StarRatings from 'react-star-ratings'
import classes from './BookItem.module.css';
import Utils from '../../Utils';
import RatingsLikesViews from '../RatingsLikesAndViews/RatingsLikesViews';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom"

function getFontThumUrl(book) {
  return `${book.frontThumbURL}?${book.updateDate}`;
}

const BookItem = ({ book, width, height, style, onClick, imageWidth, imageHeight, starColor }) => {
  const itemWidth = width;
  const itemHeight = height;
  const imgWidth = imageWidth;
  const imgHeight = imageHeight;
  const user = useSelector(state => state.user.user || {});

  return (
    <Link to={`/details/${book.urlSlag}`} >
    <div className={classes.itemContainer} style={{ ...style, width: itemWidth, height: itemHeight }} >
      <img src={getFontThumUrl(book)} alt={book.title} className={classes.itemCover} style={{ width: imgWidth, height: imgHeight }} />
      <div className={classes.ratingsLikesViewsWrapper}>
        <RatingsLikesViews likes={book.likes || 0} views={book.views || 0} ratings={Math.ceil(book.overallRating) || 0} eyeStyle={{ filter: 'invert(1)' }} style={{ color: 'white' }} />
      </div>
      <p className={classes.itemAuthor}>{`By ${book.author}`}</p>
      <div className={classes.starRatingAndPrice}>
        <p className={classes.itemPrice}>{Utils.getRupeeSymbol} {`${user.isSchoolShipped ? book.schoolPrice : book.regularPrice || book.regularPrice}`}</p>
        <StarRatings
          rating={book.overallRating || 0}
          starRatedColor={starColor || "#FBB805"}
          numberOfStars={5}
          starDimension="1rem"
          starSpacing="0.125rem"
        />
      </div>
    </div>
    </Link> 
  );
}

export const IncompleteBookItem = ({ book, width, height, style, onClick }) => {
  const itemWidth = width || "16rem";
  const itemHeight = height || "25rem";
  const bookTitle = book.title || "Untitled";

  return (
    <div className={classes.itemContainer} style={{ ...style, width: itemWidth, height: itemHeight }} onClick={() => onClick?.()}>
      <img src={getFontThumUrl(book)} alt={bookTitle} className={classes.itemCover} />
      <h2 className={classes.itemTitle}>{bookTitle}</h2>
    </div>
  );
}

export default BookItem;
