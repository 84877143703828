import React from "react";
import classes from "./Pagination.module.css";
import previousLabelIcon from "../../assets/images/pagination-previous-icon.svg";
import nextLabelIcon from "../../assets/images/pagination-next-icon.svg";
import Utils from "../../Utils";

function Pagination({ totalPages = 10, currentPage = 1, setCurrentPage }) {
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    let liTags = [];
    let beforePage = currentPage - 1;
    let afterPage = currentPage + 1;
    if (currentPage > 2) {
      liTags.push(
        <li
          key="first"
          className={Utils.getClasses(classes.first, classes.numb)}
          onClick={() => handlePageChange(1)}
        >
          <span>1</span>
        </li>
      );
      if (currentPage > 3) {
        liTags.push(
          <li key="dots1" className={classes.dots}>
            <span>...</span>
          </li>
        );
      }
    }

    for (let plength = beforePage; plength <= afterPage; plength++) {
      if (plength > totalPages) {
        continue;
      }
      if (plength === 0) {
        plength = plength + 1;
      }
      liTags.push(
        <li
          key={plength}
          className={Utils.getClasses(
            classes.numb,
            currentPage === plength && classes.active
          )}
          onClick={() => handlePageChange(plength)}
        >
          <span>{plength}</span>
        </li>
      );
    }

    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        liTags.push(
          <li key="dots2" className={classes.dots}>
            <span>...</span>
          </li>
        );
      }
      liTags.push(
        <li
          key="last"
          className={Utils.getClasses(classes.last, classes.numb)}
          onClick={() => handlePageChange(totalPages)}
        >
          <span>{totalPages}</span>
        </li>
      );
    }
    return liTags;
  };

  return (
    <div className={classes.pagination}>
      <button
        disabled={!(currentPage > 1)}
        className={Utils.getClasses(classes.btn, classes.prev)}
        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
      >
        <img src={previousLabelIcon} alt="next" />
      </button>
      <ul>{renderPagination()}</ul>
      <button
        disabled={!(currentPage < totalPages)}
        className={Utils.getClasses(classes.btn, classes.next)}
        onClick={() =>
          currentPage < totalPages && handlePageChange(currentPage + 1)
        }
      >
        <img src={nextLabelIcon} alt="next" />
      </button>
    </div>
  );
}

export default Pagination;
