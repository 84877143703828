import React from "react";
import Utils from "../../Utils";
import classes from "./ThemeItem.module.css";

function ThemeItem(props) {
  return (
    <div
      className={Utils.getClasses(
        classes.themeItem,
        props.selected ? classes.selected : ""
      )}
      onDoubleClick={() => props?.onDoubleClick?.()}
      onClick={() => props.onClick?.()}
      style={props.selected ? {} : { ...props.style }}
    >
      <img className={classes.themeImage} src={props.themeImage} alt="" />
      <div className={classes.themeName}>
        {props.themeName?.split("_").map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < props.themeName.split("_").length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>

      {props.ai && (
        <div className={classes.aiLogo}>
          <span>AI</span>
        </div>
      )}
    </div>
  );
}

export default ThemeItem;
