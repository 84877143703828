import axios from 'axios';
import { taggedImagesAction } from './TaggedImageSlice';

function getTaggedImages(filter) {
    return axios({
        url: `/designer/taggedImages?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: "get",
        responseType: "json",
    });
}

export function getTaggedImagesCount(where = {}) {
    return axios({
        url: `/designer/taggedImages/count?where=${encodeURIComponent(JSON.stringify(where))}`,
        method: "get",
        responseType: "json"
    })
}


export function fetchTaggedImages(dispatch, filter, f) {
    return getTaggedImages(filter).then(({ data }) => {
        if (data.success) {
            dispatch(taggedImagesAction.fetchTaggedImages({ images: data.data }));
            dispatch(taggedImagesAction.fetchFilter(f));
        }
    });
}

export function addNewTaggedImages(dispatch, filter) {
    return getTaggedImages(filter).then(({ data }) => {
        if (data.success) {
            dispatch(taggedImagesAction.addTaggedImages({ images: data.data }));
        }
    });
}

