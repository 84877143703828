import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewTaggedImages,
  fetchTaggedImages,
  getTaggedImagesCount,
} from "./TaggedImageAction";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import classes from "./TaggedImage.module.css";
import { addImageToCanvas } from "../../objectUtils";
import { useFabricCanvas } from "../../FabricCanvas";

const PAGE_SIZE = 50;

function SearchImage({ visible }) {
  const searchItems = useSelector((state) => state.taggedImage.items);
  const initialSearchDate = useSelector(
    (state) => state.taggedImage.initialImagesFilter || {}
  );
  const [imagesCount, setImagesCount] = useState();
  const [searchData, setSearchData] = useState({
    text: "",
    offset: 0,
  });
  const dispatch = useDispatch();
  const canvas = useFabricCanvas();

  useEffect(() => {
    if (
      initialSearchDate?.offset !== searchData.offset ||
      initialSearchDate?.text !== searchData.text
    ) {
      let filter = {
        offset: searchData.offset,
        limit: PAGE_SIZE,
        sortBy: "updateTime DESC",
      };

      if (searchData.text) {
        filter.where = {
          description: searchData.text,
        };
      }

      setHasMore(false);

      let promise;
      if (searchData.offset === 0) {
        promise = fetchTaggedImages(dispatch, filter, searchData);
        if (initialSearchDate?.text !== searchData.text)
          getTaggedImagesCount(filter?.where).then(({ data }) => {
            if (data.success) {
              setImagesCount(data.data.count);
            }
          });
      } else {
        promise = addNewTaggedImages(dispatch, filter);
      }

      promise.finally(() => {
        setHasMore(true);
      });
    }
  }, [searchData, initialSearchDate, dispatch, setImagesCount]);

  function searchImages(searchText) {
    setSearchData({
      text: searchText.trim(),
      offset: 0,
    });
  }

  const [hasMore, setHasMore] = useState(true);
  const fetchMoreData = () => {
    if (searchItems.length >= imagesCount) {
      setHasMore(false);
      return;
    }

    setSearchData((prevState) => {
      let newState = {
        ...prevState,
      };
      newState.offset += PAGE_SIZE;
      return newState;
    });
  };

  return (
    <Container visible={visible} title={"Images"}>
      <SearchBar
        className={classes.searchBar}
        placeholder="Search Image"
        onSearch={searchImages}
      />
      {searchItems.length > 0 ? (
        <InfiniteScroll
          dataLength={searchItems.length}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{
            display: "grid",
            gap: "0.5rem",
            gridTemplateRows: "100px",
            gridTemplateColumns: "47% 47%",
            overflowX: "hidden",
          }}
          height={"100%"}
          endMessage={
            <p className={classes.endMessage}>Yay! You have seen it all.</p>
          }
        >
          {searchItems?.map((img, idx) => {
            return (
              <div key={idx} className="itemWrap">
                <div
                  className="image-item-thumb-wrap"
                  onClick={() => {
                    let taggedImages = canvas.getLayerById("taggedImages");
                    if (!taggedImages) {
                      taggedImages = canvas.getLayerById("contents");
                    }
                    if (taggedImages) {
                      addImageToCanvas(img, 0.3, null, null).then((obj) => {
                        taggedImages.add(obj);
                        canvas.fire("object:moved", { target: obj });
                        canvas.setActiveObject(obj);
                      });
                    }
                  }}
                >
                  <img
                    className={classes.taggedImage}
                    src={img?.thumbPath}
                    alt="image"
                  />
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      ) : (
        <div style={{ marginTop: "3rem", height: "15rem" }}>
          <h3 className="emptyMessage">No matches found !</h3>
        </div>
      )}
    </Container>
  );
}

export default SearchImage;
