import React, { useRef, useEffect } from "react";
import StarAuthorCard from "../../../components/StarAuthorCard/StarAuthorCard";
import classes from "./StarAuthors.module.css";
import useScreenSize from "../../../custom-hooks/UseScreenSize";
import Carousel from "react-elastic-carousel";
import Marquee from "react-fast-marquee";

const carouselAutoPlaySpeed = 2000;

function StarAuthors({ authors = [] }) {
  const carouselRef = useRef();
  const { screenSize } = useScreenSize();

  const resetTimeoutRef = useRef(null);

  const totalPages = screenSize < 577 ? 1 : screenSize > 1200 ? 5 : 2;
  const showPages = Math.ceil(authors.length / totalPages);

  const onCurrentChange = (c, idx) => {
    if (resetTimeoutRef.current) {
      clearTimeout(resetTimeoutRef.current);
    }
    if (idx + 1 === showPages) {
      resetTimeoutRef.current = setTimeout(() => {
        carouselRef.current?.goTo?.(0);
      }, carouselAutoPlaySpeed);
    }
  };

  useEffect(() => {
    return () => {
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
    };
  }, [resetTimeoutRef]);

  return (
    <div className={classes.starAuthors}>
      <h2 className={classes.sectionHeading}>
        <span>Some Of Our Star Authors</span>
      </h2>
      <Marquee>
        {authors?.map((item, idx) => {
          return (
            <StarAuthorCard
              key={idx}
              triangle={item?.triangle}
              authorImage={item?.startAuthorPhotoURL}
              authorName={item?.fullName}
            />
          );
        })}
      </Marquee>
    </div>
  );
}

export default StarAuthors;
