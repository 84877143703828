import React, { Suspense, useEffect, useRef, useState } from 'react';
import { deleteReview } from '../Order/ProductOrderActions';
import { booksActions } from '../Books/BooksSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addLike, addView, getBookReviews, getBookReviewsCount, getFilteredBooksCount, getPublishedBook, getPublishedBookByUrlSlag, getSimilarBooks, removeLike } from '../Books/BooksActions';
import { addNewCartItem, getUserCart } from '../AddCartScreen/AddCartSlice';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import MainFooter from '../../components/MainFooter/MainFooter';
import classes from "./ProductScreen.module.css";
import Card from '../../components/Card/Card';
import ReviewItem from '../../components/ReviewItem/ReviewItem';
import StarRatings from 'react-star-ratings';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import AddReview from '../../components/AddReview/AddReview';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import BookItem from '../../components/BookItem/BookItem';
import PdfViewer from '../../components/Dialog/PdfViewer';
import cartImage from '../../assets/productScreenIcon/icon-cart.svg';
import bookImage from '../../assets/productScreenIcon/icon-book.svg';
import shareIcon from "../../assets/productScreenIcon/icon-share.svg";
import heartIcon from '../../assets/productScreenIcon/icon-heart.svg';
import likedHeartIcon from '../../assets/productScreenIcon/icon-heart-liked.svg'
import Utils from '../../Utils';
import faceBook from '../../assets/social/share-social-media/facebook.png';
import whatsapp from '../../assets/social/share-social-media/whatsapp.png';
import telegram from '../../assets/social/share-social-media/telegram.png';
import linkedIn from "../../assets/social/share-social-media/linkedin.png";
import copy from "../../assets/copy-text-icon.svg"
import eyeIcon from '../../assets/ratingsLikesStarIcons/icon-eye.svg'
import Consts from '../../Consts';
import previousLabelIcon from "../../assets/images/pagination-previous-icon.svg";
import nextLabelIcon from "../../assets/images/pagination-next-icon.svg";
import ReactPaginate from "react-paginate";
import Metatag from '../../components/Metatag';
import ModalDialog from '../../components/Dialog/Dialog';

function getFontThumUrl(book) {
    return `${window.origin}${book.frontThumbURL}?${book.updateDate}`;
}

const baseURL = Consts.BASE_URL;

function getBackThumUrl(book) {
    return `${book.backThumbURL}?${book.updateDate}`;
}

const ProductPictures = React.lazy(() => import('../../components/ProductPictures/ProductPictures'));

const pageSize = 10;

function ProductScreen({ match: { params } }) {
    const urlSlag = params.urlSlag;
    const dispatch = useDispatch();
    const history = useHistory()
    const [isSocialMedia, setIsSocialMedia] = useState(false);
    const product = useSelector(state => state.books.publishedBook || {});
    const similarBooks = useSelector(state => state.books.similarBooks) || [];
    const reviews = useSelector(state => state.books.reviews) || [];
    const userDetails = useSelector(state => state.user?.user);
    const cartItems = useSelector((state) => state.addCart.cartItems);
    const [url, setUrl] = useState();
    const [cartBtnText, setCartBtnText] = useState('Add to cart');
    const [bookId, setbookId] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [reviewPageNumber, setReviewPageNumber] = useState(1);
    const [reviewTotalPages, setReviewTotalPages] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState({ show: false, id: "" });

    useEffect(() => {
        handleAddToCart();
    }, [cartItems]);

    useEffect(() => {
        if (urlSlag) {
            getPublishedBookByUrlSlag(dispatch, urlSlag).then(setbookId);
        }
    }, [urlSlag]);

    useEffect(() => {
        if (bookId) {
            addView(bookId);
            getBookReviewsCount(bookId).then(function (response) {
                let data = response.data;
                setReviewTotalPages(Math.ceil(data.data.count / pageSize));
            });
            getFilteredBooksCount({
                theme: product?.theme,
                status: "PUBLISHED",
            }).then(function (response) {
                let data = response.data;
                setTotalPages(Math.ceil(data.data.count / pageSize));
            });
        }
    }, [bookId]);

    useEffect(() => {
        if (bookId) {
            getBookReviews(dispatch, bookId, reviewPageNumber);
        }
    }, [bookId, reviewPageNumber]);

    const interactions = product?.interactions || {};
    let isLiked = interactions['LIKE']?.byMe || false;

    useEffect(() => {
        if (product?.theme) {
            let filter = {
                where: {
                    theme: product.theme,
                    status: "PUBLISHED",
                },
                bookId,
                offset: (pageNumber - 1) * pageSize,
                limit: pageSize,
            };
            getSimilarBooks(dispatch, filter);
        }
    }, [product, pageNumber]);

    function previewHandler() {
        const pdfPath = `${window.origin}/designer/books/preview/${product.id}?${userDetails?.id || ''}${product.updateDate}`;
        setUrl(`${window.origin}/flipbook/index.html?file=${pdfPath}`)
    }

    const [selectedPicture, setSelectedPicture] = useState(0);
    const handleAddToCart = () => {
        const bookAlreadyExistsInCart = cartItems.some((item) => item.id === bookId);
        if (bookAlreadyExistsInCart) {
            setCartBtnText('Remove from cart');
        } else {
            setCartBtnText('Add to cart');
        }
    };

    const rupee = Utils.getRupeeSymbol;

    const shareRef = useRef();
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isSocialMedia && shareRef.current && !shareRef.current.contains(e.target)) {
                setIsSocialMedia(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isSocialMedia]);

    function orderNowHandler() {
        const userCart = getUserCart();
        const index = userCart.findIndex((x) => bookId === x.id);
        if (index <= -1) {
            dispatch(addNewCartItem({ ...product, count: 1, isProofReadingService: false }));
            history.push(`${baseURL}/cart`);
        }
        history.push(`${baseURL}/cart`);
    }

    const shareUrl = `${window.origin}/designer/share/book/slug/${product.urlSlag}`;
    const faceBookShare = `${shareUrl}?ogUrl=${encodeURIComponent('https://hi-in.facebook.com/')}`;

    function handlePageChange(e) {
        setPageNumber(e.nextSelectedPage + 1);
    }

    function handleReviewPageChange(e) {
        setReviewPageNumber(e.nextSelectedPage + 1);
    }

    function deleteConfirmHandler(id) {
        setShowConfirmation({ show: true, id: id })
    }

    async function deleteReviewHandler() {
        try {
            const res = await deleteReview(showConfirmation.id);
            if (res.status === 200) {
                if (res.data.success) {
                    dispatch(booksActions.deleteReview(showConfirmation.id));
                    setShowConfirmation({ show: false, id: "" });
                } else {
                    setShowConfirmation({ show: false, id: "" });
                }
            }
        } catch (err) {
            setShowConfirmation({ show: false, id: "" });
            console.log(err);
        }
    }

    return <>
        {showConfirmation.show && (
            <ModalDialog title={"Confirmation"} content={"Are you sure you want to delete this review?"}
                footer={<div>
                    <SecondaryButton style={{ marginRight: "1rem" }}
                        onClick={() => setShowConfirmation({ show: false, id: "" })}>No</SecondaryButton>
                    <PrimaryButton onClick={deleteReviewHandler}>Yes</PrimaryButton> </div>}
                onClose={() => setShowConfirmation({ show: false, id: "" })}
            />)}
        <div className={classes.mainContainer}>
            <Metatag name="title" content={product.title} />
            {
                product.description &&
                <Metatag name="description" content={product.description} />
            }
            <div className={classes.paddedContainer}>
                <div className={classes.box}>
                    <Card width="100%">
                        <div className={classes.productDetails}>
                            <div className={classes.productPictures}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ProductPictures
                                        pictures={[getFontThumUrl(product), getBackThumUrl(product)]}
                                        selected={selectedPicture}
                                        onSelectionChange={(selectedIndex) => { setSelectedPicture(selectedIndex) }}
                                    />
                                </Suspense>
                            </div>
                            <div className={classes.productText} >
                                <div className={classes.shareButton} ref={shareRef}>
                                    <div className={classes.shareImage} onClick={() => setIsSocialMedia(!isSocialMedia)}>
                                        <img src={shareIcon} alt="Share Icon" />
                                    </div>
                                    <p className={classes.shareTitle} onClick={() => setIsSocialMedia(!isSocialMedia)}>Share</p>
                                    {
                                        isSocialMedia && <div className={classes.toggledDropDown}>
                                            <div className={classes.iconLine}>
                                                <img className={classes.socialMediaIcon} src={faceBook} alt="facebook" />
                                                <a className={classes.link} href={`https://www.facebook.com/sharer/sharer.php?u=${faceBookShare}`} target='_blank' rel="noreferrer"> Facebook </a>
                                            </div>
                                            <div className={classes.iconLine}>
                                                <img className={classes.socialMediaIcon} src={whatsapp} alt="whatsapp" />
                                                <a className={classes.link} href={`https://api.whatsapp.com/send?text=${shareUrl}%0A%0APreview%2C%20Like%2C%20Share%20%26%20Buy%20my%20new%20book%20%0A%0AProud%20Author%20at%20Bookalooza%0AWrite%2FPublish%2FEarn`} target="_blank" rel="noreferrer">WhatsApp</a>
                                            </div>
                                            <div className={classes.iconLine}>
                                                <img className={classes.socialMediaIcon} src={telegram} alt="telegram" />
                                                <a className={classes.link} href={`https://telegram.me/share/url?url=${shareUrl}&text=%0A%0APreview%2C%20Like%2C%20Share%20%26%20Buy%20my%20new%20book%20%0A%0AProud%20Author%20at%20Bookalooza%0AWrite%2FPublish%2FEarn`} target="_blank">Telegram</a>
                                            </div>
                                            <div className={classes.iconLine}>
                                                <img className={classes.socialMediaIcon} src={linkedIn} alt="linkeIn" />
                                                <a className={classes.link} href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} target="_blank">LinkedIn</a>
                                            </div>
                                            <div className={classes.iconLine}>
                                                <img className={classes.socialMediaIcon} src={copy} alt="copy" />
                                                <p className={classes.link} onClick={() => window.navigator.clipboard.writeText(window.location)}>Copy to clipboard</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <h1 className={classes.productTitle}>{product.title}</h1>
                                <p className={classes.productAuthor}>Author: {product.author}</p>
                                <p className={classes.productPrice}>{`${rupee}${userDetails.isSchoolShipped ? product.schoolPrice : product.regularPrice || product.regularPrice}`}</p>
                                <p className={classes.deliverydisclaimer}>+Delivery Charges</p>
                                {
                                    product.description &&
                                    <p className={classes.description}>{product.description}</p>
                                }
                                <div className={classes.ratingsLikesViews}>
                                    <div className={classes.ratings}>
                                        <p className={classes.ratingsLikesViewsTitles}>Ratings </p>
                                        <div className={classes.ratingsLikesViewsContent}>
                                            {Math.ceil(product.overallRating) || 0}
                                            <span className={classes.starImage}><FontAwesomeIcon icon={faStar} /></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={classes.ratingsLikesViewsTitles}>Likes</p>
                                        <div className={classes.ratingsLikesViewsContent}>{product.likes || 0} <span className={classes.heartImage}><FontAwesomeIcon icon={faHeart} /></span></div>
                                    </div>
                                    <div>
                                        <p className={classes.ratingsLikesViewsTitles}>Views</p>
                                        <div className={classes.ratingsLikesViewsContent}>{interactions['VIEW']?.count || 0} <span className={classes.eyeImage}><img src={eyeIcon} alt="" /></span></div>
                                    </div>
                                </div>
                                <p className={classes.productSummary}>{product.summary}</p>
                                <div className={classes.actions}>
                                    <PrimaryButton className={classes.actionBtn} style={{ marginRight: "1rem" }} onClick={() => {
                                        if (!Utils.isUserLoggedIn()) {
                                            history.push(`${baseURL}/sign-in`)
                                        }
                                        else {
                                            dispatch(addNewCartItem({ ...product, count: 1, isProofReadingService: false }));
                                        }
                                    }}>
                                        {cartBtnText}
                                        <img className={classes.buttonImage} src={cartImage} alt='' />
                                    </PrimaryButton>
                                    <SecondaryButton style={{ border: '1px solid #E8B03E', color: '#E8B03E', borderRadius: '1.75rem' }} onClick={previewHandler}>
                                        Preview
                                        <img className={classes.buttonImage} src={bookImage} alt='' />
                                    </SecondaryButton>
                                    {
                                        url && <PdfViewer
                                            contentClassName={classes.eulaContainer}
                                            className={classes.modelDialog}
                                            url={url}
                                            onClose={e => setUrl('')}
                                            publishHandler={e => orderNowHandler()}
                                            primaryButtonText={"Order now"}
                                        />
                                    }
                                </div>
                                <div className={classes.giveMeLikeContainer}>
                                    <div className={classes.likeImageWrapper + " cursorPointer"} onClick={e => {
                                        let cb = isLiked ? removeLike : addLike;
                                        window.loading(true);
                                        cb(bookId).then(e => getPublishedBook(dispatch, bookId)).finally(e => {
                                            window.loading(false);
                                        });
                                    }}>
                                        <img src={isLiked ? likedHeartIcon : heartIcon} alt="like" />
                                    </div>
                                    {isLiked ? '' : <p className={classes.likeTitle}>Hey give me a like !</p>}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className={classes.box}>
                <Card style={{ flex: 1 }}>
                    <div className={classes.reviews}>
                        <div className={classes.reviewTitleWrapper}>
                            <h2 className={classes.userReviews}>User Reviews</h2>
                            <div>
                                <StarRatings
                                    rating={Math.ceil(product.overallRating) || 1}
                                    starRatedColor="#FBB805"
                                    numberOfStars={5}
                                    starDimension="1.15rem"
                                    starSpacing="0.125rem"
                                />
                                <span className={classes.xOfyStars}>{Math.ceil(product.overallRating) || 0} out of 5 stars</span>
                            </div>
                        </div>
                        {
                            (product?.authorId !== userDetails?.id) && <AddReview bookId={bookId} key={bookId} setReviewTotalPages={setReviewTotalPages} />
                        }
                        <div className={classes.topReviewsWrapper}>
                            <h3 className={classes.topReviews}>Top Reviews</h3>
                            {reviews.map(review => <React.Fragment key={review.id}>
                                <ReviewItem onClick={deleteConfirmHandler} item={review} />
                                <div style={{ height: "1rem" }} />
                            </React.Fragment>)}
                            {
                                reviews.length > 0 ?
                                    <ReactPaginate
                                        key={"Pagination"}
                                        nextLabel={<><img className={reviewPageNumber === reviewTotalPages + 1 ? classes.disabledButton : ''} src={nextLabelIcon} alt="next" /></>}
                                        onClick={handleReviewPageChange}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        pageCount={reviewTotalPages}
                                        previousLabel={<><img className={reviewPageNumber === 2 ? classes.disabledButton : ''} src={previousLabelIcon} alt="next" /></>}
                                        pageClassName={classes.pageItem}
                                        pageLinkClassName={classes.pageLink}
                                        disableInitialCallback
                                        previousClassName={classes.pageItemPrevious}
                                        previousLinkClassName={classes.pageLink}
                                        nextClassName={classes.pageItem}
                                        nextLinkClassName={classes.pageLink}
                                        breakLabel="..."
                                        breakClassName={classes.break}
                                        breakLinkClassName="page-link"
                                        containerClassName={classes.pagination}
                                        activeClassName={classes.pageItemActive}
                                    />
                                    : <></>}
                        </div>
                    </div>
                </Card>
            </div>
            <div className={classes.box}>
                <Card width="100%">
                    <div className={classes.similarBooks}>
                        <div>
                            <h2 className={classes.similarBooksHeader}>View Similar Books</h2>
                        </div>
                        <div className={classes.bookList}>
                            {similarBooks.length > 0 ? <>{
                                similarBooks.map((book) => {
                                    return (
                                        <BookItem
                                            key={book.id}
                                            book={book}
                                            onClick={() => {
                                                Utils.showBookDetails(history, book.urlSlag);
                                            }}
                                        />
                                    );
                                })
                            } </> : <>
                                <span>No similar books are available.</span>
                            </>
                            }
                        </div>
                        <ReactPaginate
                            key={"Pagination"}
                            nextLabel={<><img className={pageNumber === totalPages + 1 ? classes.disabledButton : ''} src={nextLabelIcon} alt="next" /></>}
                            onClick={handlePageChange}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={totalPages}
                            previousLabel={<><img className={pageNumber === 2 ? classes.disabledButton : ''} src={previousLabelIcon} alt="next" /></>}
                            pageClassName={classes.pageItem}
                            pageLinkClassName={classes.pageLink}
                            disableInitialCallback
                            previousClassName={classes.pageItemPrevious}
                            previousLinkClassName={classes.pageLink}
                            nextClassName={classes.pageItem}
                            nextLinkClassName={classes.pageLink}
                            breakLabel="..."
                            breakClassName={classes.break}
                            breakLinkClassName="page-link"
                            containerClassName={classes.pagination}
                            activeClassName={classes.pageItemActive}
                        />
                    </div>
                </Card>
            </div>
            <MainFooter />
        </div>
    </>;
}

export default ProductScreen;