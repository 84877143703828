import React, { useState, useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  getStoreBooks,
  getStoreBookCount,
  getCustomStoreSections,
} from "../BooksActions";
import classes from "./BookStore.module.css";
import Utils from "../../../Utils";
import SearchBar from "../../../components/SearchBar/SearchBar";
import StoreSection from "./StoreSection";
import Pagination from "../../../components/Pagination/Pagination";
import MainFooter from "../../../components/MainFooter/MainFooter";
import bannerImage from "../../../assets/images/store-banner.svg";
import Select from "../../../components/Select";

const BookItem = lazy(() => import("../../../components/BookItem/BookItem"));

const SortBy = {
  author: "author ASC",
  title: "title ASC",
  publishTime: "publishDate DESC",
  publishASC: "publishDate ASC",
  rating: "overallRating DESC",
};

const sortOptions = [
  {
    value: SortBy.author,
    label: "Author's Name",
  },
  {
    value: SortBy.title,
    label: "Book Title",
  },
  {
    value: SortBy.publishTime,
    label: "Newest First",
  },
  {
    value: SortBy.publishASC,
    label: "Oldest First",
  },
  {
    value: SortBy.rating,
    label: "Book Rating",
  },
];

const pageSize = 25;

function BookStore() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const books = useSelector((state) => state.books.storeBooks);
  const initialFilter = useSelector((state) => state.books.storeBooksFilter);
  const customStore = useSelector(
    (state) => state.books.customStoreSectionBooks || {}
  );
  const totalPages = useSelector((state) => state.books.storeBooksCounts || 0);
  const [customSectionBooks, setCustomSectionBooks] = useState({});
  const [customSectionHeadings, setCustomSectionHeadings] = useState([]);

  const [searchKey, setSearchKey] = useState(initialFilter?.searchKey || "");
  const [pageNumber, setPageNumber] = useState(initialFilter?.pageNumber || 1);
  const [sortBy, setSortBy] = useState(
    initialFilter?.sortBy || SortBy.publishTime
  );

  useEffect(() => {
    !customStore.hasOwnProperty("books") && getCustomStoreSections(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (searchKey !== initialFilter?.searchKey) {
      searchParams.set("pageNumber", 1);
      setPageNumber(1);
    }
  }, [initialFilter, searchKey]);

  useEffect(() => {
    if (initialFilter) {
      setPageNumber(initialFilter.pageNumber);
      setSearchKey(initialFilter.searchKey);
      setSortBy(initialFilter.sortBy);
    }
  }, [initialFilter]);

  useEffect(() => {
    if (pageNumber && searchKey !== undefined && sortBy) {
      if (
        !totalPages ||
        initialFilter?.pageNumber !== pageNumber ||
        initialFilter.searchKey !== searchKey ||
        initialFilter?.sortBy !== sortBy
      ) {
        const regex = `.*${searchKey?.split(" ").join("\\s*")}.*`;
        let f = {
          pageNumber: pageNumber,
          searchKey: searchKey,
          sortBy: sortBy,
        };
        let filter = {
          where: {
            $or: [
              {
                author: {
                  $regex: regex,
                  $options: "i",
                },
              },
              {
                title: {
                  $regex: regex,
                  $options: "i",
                },
              },
            ],
            status: "PUBLISHED",
          },
          sortBy: sortBy,
          offset: (pageNumber - 1) * pageSize,
          limit: pageSize,
        };
        history.push({
          search: new URLSearchParams(f).toString(),
        });
        getStoreBooks(dispatch, filter, f);
        getStoreBookCount(dispatch, filter.where, pageSize);
      }
    }
  }, [searchKey, sortBy, pageNumber, dispatch]);

  useEffect(() => {
    if (customStore.headings) {
      let headingSection = [...customStore?.headings];
      const books = customStore?.books;

      const groupedBooks = {};
      books?.forEach((book) => {
        if (book?.customStoreSection) {
          book.customStoreSection.forEach((id) => {
            if (groupedBooks[id]) {
              groupedBooks[id].push(book);
            } else {
              groupedBooks[id] = [book];
            }
          });
        }
      });

      setCustomSectionHeadings(headingSection);
      setCustomSectionBooks(groupedBooks);
    }
  }, [customStore]);

  let renderIndex = 0;

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.paddedContainer}>
          <div className={classes.imageWrapper}>
            <img
              src={bannerImage}
              className={classes.banner}
              alt="Store banner"
            />
            <h1 className={classes.bannerHeading}>Bookstore</h1>
          </div>
          {Boolean(customSectionHeadings.length) &&
            customSectionHeadings.map((heading) => {
              if (customSectionBooks[heading.id]?.length > 0) {
                const theme = renderIndex % 2 === 0 ? "purple" : "orange";
                renderIndex++;
                return (
                  <StoreSection
                    heading={heading.heading}
                    theme={theme}
                    key={heading.id}
                    books={customSectionBooks[heading.id]}
                  />
                );
              } else return <></>;
            })}
          <div className={classes.innerContainer}>
            <div className={classes.search}>
              <SearchBar
                placeholder={"Search By Title, Author"}
                className={classes.searchBoxWrapper}
                onSearch={setSearchKey}
                search={searchKey}
              />
              <Select
                key={"Select"}
                closeMenuOnSelect={true}
                options={sortOptions}
                onChange={(e) => {
                  const selected = sortOptions.find(
                    (opt) => e.value === opt.value
                  );
                  setSortBy(selected?.value);
                  setPageNumber(1);
                }}
                value={sortOptions.find((i) => i.value === sortBy)}
                placeholder="Sort By..."
                clearable={true}
                styles={{
                  control: (e) => ({
                    fontFamily: "Poppins",
                    width: "12rem",
                    cursor: "pointer",
                    background: "white",
                    border: "1px solid black",
                  }),
                  option: (base, state) => {
                    return { fontFamily: "Poppins" };
                  },
                }}
              />
            </div>
            <div className={classes.bookList}>
              {books.map((book) => {
                return (
                  <Suspense fallback={<div>Loading...</div>}>
                    <BookItem
                      key={book.id}
                      book={book}
                      style={{ padding: "0.8rem" }}
                      onClick={() => {
                        Utils.showBookDetails(history, book.urlSlag);
                      }}
                    />
                  </Suspense>
                );
              })}
            </div>
            <Pagination
              currentPage={parseInt(pageNumber)}
              totalPages={totalPages}
              setCurrentPage={setPageNumber}
            />
          </div>
        </div>
      </div>
      <MainFooter key={"Footer"} />
    </>
  );
}

export default BookStore;
