import React, { useRef, useState } from "react";
import { usePageContext } from "./BookContext";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFullTheme } from "../Books/NewBook/ThemeActions";
import { fetchStoryLines } from "./AIHelp/AIHelpAction";
import ToolbarButton from "../../components/Buttons/ToolbarButton";
import DecorativeTextView from "./ToolPanels/TextView/DecorativeTextView";
import TextView from "./ToolPanels/TextView/TextView";
import ImageWidget from "./ToolPanels/ImageView";
import AboutAuthor from "./ToolPanels/Author/AboutAuthor";
import textToolIcon from "../../assets/text_tool.svg";
import speechIcon from "../../assets/icon-microphone.svg";
import themesToolIcon from "../../assets/themes_tool.svg";
import aiHelpToolIcon from "../../assets/ai_help_tool.svg";
import decorativeToolIcon from "../../assets/decorative_tool.svg";
import clipartToolIcon from "../../assets/clipart_tool.svg";
import uploadImageIcon from "../../assets/upload-image-icon.svg";
import backgroundThemeImageIcon from "../../assets/background-theme-icon.svg";
import classes from "./LeftController.module.css";
import Utils from "../../Utils";
import UploadImages from "./ToolPanels/UploadUserImages/UploadImages";
import TaggedImages from "./ToolPanels/SearchImage/TaggedImages";
import AIHelp from "./AIHelp/AIHelp";
import closeIcon from "../../assets/close.svg";
import SpeechToText from "./ToolPanels/SpeechToText/SpeechToText";
import BackgroundIcon from "../../assets/icon-background.svg";
import AboutAuthorIcon from "../../assets/icon-about-author.svg"
import BackCovers from "./ToolPanels/BackCovers/BackCovers";

const controls = [
  {
    id: "image",
    displayName: "Designs",
    icon: backgroundThemeImageIcon,
    Component: function ({ visible, taggablePanel, setOpen }) {
      const imageData = useSelector((state) => state.themes.allThemes);
      return (
        <ImageWidget
          visible={visible}
          imageData={imageData}
          title="Designs"
          taggablePanel={taggablePanel}
          setOpen={setOpen}
          getCategory={(categories, idx) => getFullTheme(categories[idx])}
        />
      );
    },
    access: new Set(["frontPage", "regularPage"]),
    isMountable: function (page) {
      return this.access.has(page?.type);
    },
  },
  {
    id: "Backgrounds",
    displayName: "Backgrounds",
    icon: BackgroundIcon,
    Component: function ({ visible, taggablePanel, setOpen }) {
      const imageData = useSelector((state) => state.themes.backgrounds)
      return (
        <ImageWidget
          visible={visible}
          imageData={imageData}
          title="Backgrounds"
          hasCategory={false}
          taggablePanel={taggablePanel}
          setOpen={setOpen}
          getCategory={(categories, idx) => getFullTheme(categories[idx])}
        />
      );
    },
    access: new Set(["frontPage", "regularPage"]),
    isMountable: function (page) {
      return this.access.has(page?.type);
    },
  },
  {
    id: "Back Cover",
    displayName: "Back Cover",
    icon: themesToolIcon,
    Component: BackCovers,
    isMountable: function (page) {
      return page?.isBackPage();
    },
  },
  {
    id: "AIHelp",
    displayName: "AI Help",
    icon: aiHelpToolIcon,
    Component: AIHelp,
    isMountable: function (page, aiThemes) {
      if (page?.isRegularPage()) {
        let bookTheme = page?.getBook().getTheme();
        if (!bookTheme) {
          return false;
        }
        return aiThemes.has(bookTheme.toLowerCase()) || false;
      } else return false;
    },
  },
  {
    id: "Author",
    displayName: "About Author",
    icon: AboutAuthorIcon,
    Component: AboutAuthor,
    isMountable: function (page) {
      return page?.isBackPage();
    },
  },
  {
    id: "Preface",
    displayName: "Preface",
    icon: themesToolIcon,
    Component: function ({ visible, taggablePanel, setOpen }) {
      const imageData = useSelector((state) => state.themes.preface);
      return (
        <ImageWidget
          visible={visible}
          imageData={imageData}
          title="Preface"
          hasCategory={false}
          showAllItemes
          taggablePanel={taggablePanel}
          setOpen={setOpen}
          getCategory={(categories, idx) => getFullTheme(categories[idx])}
        />
      );
    },
    isMountable: function (page) {
      return page?.isPreface();
    },
  },
  {
    id: "text",
    displayName: "Textbox",
    icon: textToolIcon,
    Component: TextView,
    isMountable: function (page) {
      return true;
    },
    access: new Set(["frontPage", "frontInnerPage", "backPage", "regularPage"]),
    isVisible: function (page) {
      return this.access.has(page?.type);
    },
  },
  {
    id: "speechToText",
    displayName: "Speech to text",
    icon: speechIcon,
    Component: SpeechToText,
    isMountable: function (page) {
      return true;
    },
    access: new Set(["frontPage", "backPage", "regularPage"]),
    isVisible: function (page) {
      return this.access.has(page?.type);
    },
  },
  {
    id: "decorative",
    displayName: "Heading",
    icon: decorativeToolIcon,
    Component: DecorativeTextView,
    isMountable: function (page) {
      return page?.isRegularPage();
    },
  },
  {
    id: "searchImage",
    displayName: "Images",
    icon: clipartToolIcon,
    Component: TaggedImages,
    access: new Set(["frontPage", "regularPage"]),
    isMountable: function (page) {
      return this.access.has(page?.type);
    },
  },
  {
    id: "uploadImage",
    displayName: "Upload Pictures",
    icon: uploadImageIcon,
    Component: UploadImages,
    access: new Set(["frontPage", "regularPage"]),
    isMountable: function (page) {
      return this.access.has(page?.type);
    },
  },
  {
    id: "Last page",
    displayName: "Last Page",
    icon: themesToolIcon,
    Component: function ({ visible, taggablePanel, setOpen }) {
      const imageData = useSelector((state) => state.themes.lastPage);
      return (
        <ImageWidget
          visible={visible}
          imageData={imageData}
          title="Last Page"
          hasCategory={false}
          showAllItemes
          taggablePanel={taggablePanel}
          setOpen={setOpen}
          getCategory={(categories, idx) => getFullTheme(categories[idx])}
        />
      );
    },
    isMountable: function (page) {
      return page?.isLastPage();
    },
  },
];

export default function LeftController({
  style,
  className,
  taggablePanel = true,
  setOpen,
}) {
  const dispatch = useDispatch();
  const page = usePageContext();
  const parentRef = useRef();
  const containerRef = useRef();
  const [boxShadow, setBoxShadow] = useState()
  const [active, setActive] = useState();
  const [title, setTitle] = useState("")
  const [filteredControls, setFilteredControls] = useState(controls);
  const storyLines = useSelector((s) => s.aiHelp.storyLines);

  useEffect(() => {
    if (!storyLines?.length) {
      fetchStoryLines(dispatch);
    }
  }, [storyLines]);

  useEffect(() => {
    if (!page) {
      return;
    }
    const aiThemes = new Set(storyLines?.map((i) => i.theme.toLowerCase()));
    const filtered = controls.filter((ctl) => ctl.isMountable(page, aiThemes));
    if (
      filteredControls.map((c) => c.id).join() !==
      filtered.map((c) => c.id).join()
    ) {
      setFilteredControls(filtered);
      if (!taggablePanel)
        setActive(filtered.find((i) => i.isVisible?.() ?? true).id);
    }
  }, [page, storyLines]);

  // ctrl + / to hide the sidebar
  useEffect(() => {
    const handleKeyDown = (event) => {
      const modifier = event.ctrlKey || event.metaKey;
      if (modifier && event.key === '/') {
        !taggablePanel ? setOpen(false) : setActive("");
      }
    };

    const handleClickOutside = (e) => {
      if (active && parentRef.current && !parentRef.current.contains(e.target)) {
        setActive("");
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setTitle(filteredControls.find(ctrl => ctrl.id === active)?.displayName);
  }, [active])

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current.scrollTop > 0) {
        setBoxShadow('rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px')
      } else {
        setBoxShadow("")
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [containerRef]);

  return (
    <div
      className={className}
      ref={parentRef}
      style={{ ...style, display: "flex", height: "100%" }}
    >
      <div
        className={Utils.getClasses(
          "dark-background",
          classes.toolbarContainer
        )}
      >
        {filteredControls.map((ctrl, idx) => {
          if (ctrl.isVisible?.(page) ?? true) {
            return (
              <ToolbarButton
                key={idx}
                state={{ pressed: active === ctrl.id }}
                title={ctrl.displayName}
                icon={ctrl.icon}
                onClick={(e) => setActive(prev => prev === ctrl.id ? "" : ctrl.id)}
              />
            );
          }
          return "";
        })}
      </div>
      <div
        className={Utils.getClasses("tool-panel-container", classes.container)}
        style={
          taggablePanel && !active
            ? { width: "0rem", border: "none", transition: "450ms" }
            : undefined
        }
        ref={containerRef}
      >
        <div className={classes.leftHeading} style={{ boxShadow: boxShadow }}>
          <div className={classes.heading}>{title}</div>
          <div
            className={classes.cancelActionContainer}
          // style={!taggablePanel ? { display: "none" } : undefined}
          >
            <img src={closeIcon} title="Hide (CTRL + /)" alt="" onClick={!taggablePanel ? () => setOpen(false) : () => setActive("")} />
          </div></div>
        {filteredControls.map((cmp, idx) => {
          const { Component, id } = cmp;
          return (
            <Component
              key={idx}
              visible={(cmp.isVisible?.(page) ?? true) && active === id}
              taggablePanel={taggablePanel}
              setOpen={setOpen}
            />
          );
        })}
      </div>
    </div>
  );
}
