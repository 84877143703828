import axios from '../../../../axios';
import { themesAction } from './ThemeSlice';

function getThemes(filter) {
    return axios({
        url: `/designer/themes/withoutItems?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: 'get',
        responseType: 'json'
    });
}

export async function getFullTheme(theme) {
    if (!theme) {
        return;
    }
    return await axios({
        url: `/designer/themes/${theme.id}?${theme.updateDate}`,
        method: 'get',
        responseType: 'json'
    }).then(r => {
        let theme = r.data?.data;
        theme.items.sort((a, b) => b.updateTime - a.updateTime);
        return theme;
    });
}

export function fetchAllThemes(dispatch) {
    let filter = {
        sortBy: "name ASC"
    }
    return getThemes(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            let allThemes = data.data || [],
                set = new Set(["Preface", "Last page", "Back page"]),
                themes = allThemes.filter(i => !set.has(i.name));

            dispatch(themesAction.fetchAllThemes({ themes: themes }));

            let obj = { trending: [], normal: [] };
            themes.forEach(t => {
                obj[t.trending ? 'trending' : 'normal'].push(t);
            })

            dispatch(themesAction.fetchTrendingThemes({
                themes: obj.trending.concat(obj.normal).slice(0, 5)
            }));
            dispatch(themesAction.fetchPopularThemes({
                themes: themes.filter((x) => x.popular).slice(0, 5)
            }));
            dispatch(themesAction.fetchPreface({ themes: allThemes.filter(i => i.name === 'Preface') }));
            dispatch(themesAction.fetchBackGround({ themes: allThemes.filter(i => i.name === 'Background') }));
            dispatch(themesAction.fetchLastPage({ themes: allThemes.filter(i => i.name === 'Last page') }));
            dispatch(themesAction.fetchBackPage({ themes: allThemes.filter(i => i.name === 'Back page') }));
        }
    })
}