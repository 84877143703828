import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeBooks: [],
  storeBooksCounts: 0,
  storeBooksFilter: {},
  book: null,
  currentTitle: '',
  publishedBook: null,
  books: [],
  similarBooks: [],
  reviews: [],
  homeScreenBooks: [],
  mostPopularBooks: [],
  bestSellingBooks: [],
  customStore: {},
  customStoreSectionBooks: []
};

const booksSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    fetchAllBooks: (state, action) => {
      state.books = action.payload.books;
    },
    fetchSimilarBooks: (state, action) => {
      state.similarBooks = action.payload.books;
    },
    fetchBookReviews: (state, action) => {
      state.reviews = action.payload.reviews;
    },
    fetchBook: (state, action) => {
      state.book = action.payload.book;
    },
    mostPopularBooks: (state, action) => {
      state.mostPopularBooks = action.payload.books;
    },
    bestSellingBooks: (state, action) => {
      state.bestSellingBooks = action.payload.books;
    },
    updateBookTitle: (state, action) => {
      state.currentTitle = action.payload;
    },
    fetchPublishedBook: (state, action) => {
      state.publishedBook = action.payload.book;
    },
    fetchCustomStore: (state, action) => {
      state.customStore = action.payload;
    },
    addBook: (state, action) => {
      let data = action.payload.data;
      const index = state.books.findIndex(i => i.id === data.id);
      if (index < 0) {
        state.books.unshift(data);
      } else {
        state.books[index] = data;
      }
      if (state.book?.id === data.id) {
        state.book = data;
      }
    },
    deleteBook: (state, action) => {
      const index = state.books.findIndex((book) => book.id === action.payload.id);
      if (index > -1) {
        state.books.splice(index, 1);
      }
    },
    updateBookStatus: (state, action) => {
      const index = state.books.findIndex((book) => book.id === action.payload.bookId);
      if (index > -1) {
        const currBook = state.books[index];
        const status = action.payload.status;
        currBook.status = status;
        if (currBook.id === state.book?.id) {
          state.book.status = status;
        }
      }
    },
    fetchStoreBooks: (state, action) => {
      state.storeBooks = action.payload.books;
    },
    fetchStoreBooksCounts: (state, action) => {
      state.storeBooksCounts = action.payload;
    },
    fetchStoreBooksFilter: (state, action) => {
      state.storeBooksFilter = action.payload
    },
    fetchByBooksRating: (state, action) => {
      state.homeScreenBooks = action.payload.books;
    },
    deleteReview: (state, action) => {
      const index = state.reviews.findIndex((review) => review.id === action.payload);
      if (index > -1) {
        state.reviews.splice(index, 1)
      }
    },
    fetchCustomStoreSectionBooks: (state, action) => {
      state.customStoreSectionBooks = action.payload
    }
  }
});

export const booksActions = booksSlice.actions;
export default booksSlice.reducer;

const bookCategoriesSlice = createSlice({
  name: "bookCategories",
  initialState: { categoryItems: [] },
  reducers: {
    fetchBookCategories: (state, action) => {
      state.categoryItems = action.payload.categoryItems || [];
    }
  },
});

export const bookCategoriesActions = bookCategoriesSlice.actions;
export const bookCategoriesReducer = bookCategoriesSlice.reducer;