import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    initialImagesFilter: {}
};

const taggedImagesSlice = createSlice({
    name: 'taggedImage',
    initialState,
    reducers: {
        fetchTaggedImages: (state, action) => {
            state.items = action.payload.images
        },
        addTaggedImages: (state, action) => {
            state.items.push(...action?.payload?.images)
        },
        fetchFilter: (state, action) => {
            state.initialImagesFilter = action.payload;
        }
    }
});

export const taggedImagesAction = taggedImagesSlice.actions;
export default taggedImagesSlice.reducer;