import axios from '../../../axios';
import { booksActions, bookCategoriesActions } from "./BooksSlice";
import Consts from '../../Consts';
import { usersActions } from '../Users/UsersSlice';
import Utils from '../../Utils';

function getFilteredBooks(filter) {
  return axios({
    url: `/designer/books?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    method: "get",
    responseType: "json",
  });
}

export function getFilteredBooksCount(where) {
  return axios({
    url: `/designer/books/count?where=${encodeURIComponent(JSON.stringify(where))}`,
    method: "get",
    responseType: "json",
  });
}

export function getAllBooks(dispatch, filter = {}) {
  return getFilteredBooks(filter).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(booksActions.fetchAllBooks({ books: data.data || [] }));
    }
  })
}

export function getSimilarBooks(dispatch, filter = {}, bookId) {
  return getFilteredBooks(filter).then(function (response) {
    let data = response.data;
    if (data.success) {
      const filteredData = data.data.filter(book => book.id !== bookId);
      dispatch(booksActions.fetchSimilarBooks({ books: filteredData || [] }));
    }
  })
}

export function getStoreBooks(dispatch, filter, initialFilter) {
  return getFilteredBooks(filter).then(function (response) {
    let data = response.data;
    if (data.success || data.data?.length > 0) {
      dispatch(booksActions.fetchStoreBooks({ books: data.data || [] }));
      dispatch(booksActions.fetchStoreBooksFilter(initialFilter))
    }
    return response;
  })
}

export function getStoreBookCount(dispatch, where, pageSize) {
  return getFilteredBooksCount(where).then((res) => {
    let data = res.data;
    dispatch(booksActions.fetchStoreBooksCounts(Math.ceil(data.data.count / pageSize)));
  })
}

export function getHomeScreenBooks(dispatch, sort, limit = 4) {
  let filter = {
    where: {
      status: "PUBLISHED"
    },
    sortBy: sort,
    limit: limit
  }
  return getFilteredBooks(filter).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(booksActions.fetchByBooksRating({ books: data.data || [] }));
    }
    return response;
  })
}


export function getCartBooks(ids) {
  let filter = {
    where: {
      _id: {
        $in: ids.map(e => ({ $oid: e.bookId }))
      }
    }
  }
  return getFilteredBooks(filter).then(function (response) {
    let data = response.data.data;
  
    if (Array.isArray(data)) {
      data = data.map((single) => {
        return { ...single, count: 1 };
      });
    }
  
    return { ...response, data: { ...response.data, data } };
  });
  
}

export function getBook(dispatch, bookId) {
  return axios({
    url: `/designer/books/${bookId}`,
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(booksActions.fetchBook({ book: data.data }));
      }
    })
}

export function getBookBySlag(dispatch, urlSlag) {
  return axios({
    url: `/designer/books/slag/${urlSlag}`,
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        const book = data.data;
        dispatch(booksActions.fetchBook({ book }));
        return book;
      }
    })
}

export function getPublishedBook(dispatch, bookId) {
  return axios({
    url: `/designer/books/${bookId}`,
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        data = data.data;
        const interactions = {};
        data.interactions?.forEach(i => {
          interactions[i.type] = i;
        });
        data.interactions = interactions;
        dispatch(booksActions.fetchPublishedBook({ book: data }));
      }
    })
}

export function getPublishedBookByUrlSlag(dispatch, urlSlag) {
  return axios({
    url: `/designer/books/slag/${urlSlag}`,
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        data = data.data;
        const interactions = {};
        data.interactions?.forEach(i => {
          interactions[i.type] = i;
        });
        data.interactions = interactions;
        dispatch(booksActions.fetchPublishedBook({ book: data }));
        return data.id;
      }
    })
}

export function getBookReviews(dispatch, bookId, pageNumber) {
  const pageSize = 10;
  const filter = {
    where: { bookId },
    offset: (pageNumber - 1) * pageSize,
    limit: pageSize,
  };
  return axios({
    url: `/designer/reviews?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(booksActions.fetchBookReviews({ reviews: data.data }));
      }
    })
}

export function getBookReviewsCount(bookId) {
  return axios({
    url: `/designer/reviews/count?where=${encodeURIComponent(
      JSON.stringify({ bookId })
    )}`,
    method: "get",
    responseType: "json",
  });
}

export function addBookReviews(data) {
  return axios({
    url: `/designer/reviews`,
    method: "post",
    data: data,
    responseType: "json",
  })
}

export async function updateBook(book, history) {
  let formData = await book.getBookFormData(),
    id = book.getId(),
    prom;
  if (id) {
    prom = axios({
      url: `/designer/books/${id}`,
      method: "put",
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData,
      reponseType: "json",
    });
  } else {
    prom = axios({
      url: `/designer/books`,
      method: "post",
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData,
      reponseType: "json",
    })
  }
  return await prom.then((response) => {
    let data = response.data;
    if (data.success) {
      book._updateMetadata(data.data);
    }
    return data.data;
  })
}

export function deleteBook(dispatch, data) {
  return axios({
    url: `/designer/books/${data.id}`,
    method: "delete",
    reponseType: "json",
  }).then((response) => {
    if (response.data.success) {
      dispatch(booksActions.deleteBook(data));
    }
    return response;
  })
    .catch((error) => {
      return error;
    });
}

export function fetchBookCategoriesData(dispatch) {
  let obj = { categoryItems: [] };
  return axios({
    url: `${Consts.BASE_URL}/resources/book-categories/categories.json?${Consts.APP_VERSION}`,
    method: "get",
    responseType: "json"
  }).then(function (response) {
    obj.categoryItems.push(...response.data.categories.map(i => {
      i.system = true;
      return i;
    }));
    dispatch(bookCategoriesActions.fetchBookCategories(obj));
  });
}

export async function addView(id) {
  if (Utils.isUserLoggedIn()) {
    return await axios({
      url: `/designer/interactions/view/${id}`,
      method: "post",
      reponseType: "json",
    })
  }
}

export function addLike(id) {
  return axios({
    url: `/designer/interactions/like/${id}`,
    method: "post",
    reponseType: "json",
  })
}

export function removeLike(id) {
  return axios({
    url: `/designer/interactions/unlike/${id}`,
    method: "post",
    reponseType: "json",
  })
}
/*
Get Order history by user
*/
export async function getOrders(filter) {
  const createOrderUrl = `/designer/orders?filter=${encodeURIComponent(JSON.stringify(filter))}`;
  return axios({
    url: createOrderUrl,
    method: "get",
  }).then((response) => {
    if (response.status === 200) {
      return response;
    } else {
      throw new Error('Something went wrong. Please try again');
    }
  });
}

export function getMostPopularBooks(dispatch) {
  return getFilteredBooks({
    limit: 3,
    sortBy: `likes DESC`,
    where: {
      status: 'PUBLISHED'
    }
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(booksActions.mostPopularBooks({ books: data.data || [] }));
    }
    return response;
  })
}

export function getCustomStoreSections(dispatch) {
  return axios({
    url: `/designer/customStoreSection?limit=400`,
    method: "get",
    responseType: "json",
  }).then(res => {
    const data = res.data;
    if (data.success) {
      dispatch(booksActions.fetchCustomStoreSectionBooks(data.data));
    }
  });
}

export function getBestSellingBooks(dispatch) {
  return getFilteredBooks({
    limit: 3,
    sortBy: `orders DESC`,
    where: {
      status: 'PUBLISHED'
    }
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(booksActions.bestSellingBooks({ books: data.data || [] }));
    }
    return response;
  })
}

export function fetchCustomStore(dispatch) {
  return axios({
    url: `/designer/books/customStoreSection`
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(booksActions.fetchCustomStore(data.data));
    }
    return response;
  })
}

/*
Get Notification by user
*/
export async function getNotifications(filter) {
  const url = `/designer/notifications/detailed`;
  return axios({
    url: url,
    method: "get",
  }).then((response) => {
    if (response.status === 200) {
      return response;
    } else {
      throw 'Something went wrong. Please try again';
    }
  });
}

export function deleteNotification(dispatch, id) {
  return axios({
    url: `/designer/notifications/${id}`,
    method: "delete",
    reponseType: "json",
  }).then((response) => {
    if (response.data.success) {
      dispatch(usersActions.removeNotification({ id }));
    }
    return response;
  })
    .catch((error) => {
      return error;
    });
}