import React, { useEffect, useState } from 'react';
import EmptyDialogue from '../EmptyDialogue/EmptyDialogue';
import UserDashboardHeader from '../UserDashboardHeader/UserDashboardHeader';
import classes from './MyAchievements.module.css'
import myAchievementsAnimation from '../../../../assets/dashboardImage/animation/achievement-animation.json'
import { useDispatch, useSelector } from 'react-redux';
import { getAllBooks } from '../../BooksActions';
import Utils from '../../../../Utils';
import LinkButton from '../../../../components/Buttons/LinkButton';
import shareIcon from "../../../../assets/productScreenIcon/icon-share.svg";
import downloadIcon from "../../../../assets/download-icon.svg";
import ModalDialog from '../../../../components/Dialog/Dialog';
import faceBook from '../../../../assets/social/share-social-media/facebook.png';
import whatsapp from '../../../../assets/social/share-social-media/whatsapp.png';
import telegram from '../../../../assets/social/share-social-media/telegram.png';
import linkedIn from '../../../../assets/social/share-social-media/linkedin.png';
import copyIcon from '../../../../assets/copy-text-icon.svg';
import { getCertificate } from './CertificateAction';

function MyAchievements() {
  const userDetails = useSelector(state => state.user);
  const books = useSelector(state => state.books.books) || [];
  const dispatch = useDispatch()
  useEffect(() => {
    const userId = userDetails?.user?.userId;
    if (userId) {
      getAllBooks(dispatch, { where: { userId, status: 'PUBLISHED' }, sortBy: 'updateDate DESC' });
    }
  }, [userDetails]);
  return (
    <div className={classes.main}>
      <UserDashboardHeader
        disabled
        title="Achievements"
        description="See all your achievements here !"
        helloUserText={books.length ? `Congratulations on publishing your ${books.length > 1 ? "books" : "book"}.` : ''}
      />
      {
        books.length ? <div className={classes.container}>{
          books.map((book, idx) => {
            return <AchievementBookCard key={idx} book={book} />
          })
        }</div> :
          <EmptyDialogue
            disabled
            animation={myAchievementsAnimation}
            descriptionText={"Publish a book and start achieving."}
          />
      }

    </div>
  )
}

const AchievementBookCard = ({ book }) => {
  const [isSocialMedia, setIsSocialMedia] = useState(false);
  const [copyUrl, setCopyUrl] = useState(false);

  const date = Utils.getFormattedDate(book.publishDate).split(" ");

  const viewCertificateHandler = (bookId) => {
    getCertificate(bookId).then((response) => {
      //to generate url of pdf
      const blob = new Blob([response], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(blob);
      // to open certificate pdf into a new tab.
      window.open(pdfUrl, "_blank");
    })


  }

  const downloadCertificateHandler = (bookId, bookTitle) => {
    getCertificate(bookId).then((response) => {
      const blob = new Blob([response], { type: "application/pdf" });
      Utils.downloadFromBlob(blob, `Bookalooza-certificate-${bookTitle.replace(/\n/g, " ")}.pdf`);
    })
  }

  const shareUrl = `${window.origin}/designer/share/authorBook/slug/${book.urlSlag}`;
  const faceBookShare = `${shareUrl}?ogUrl=${encodeURIComponent('https://hi-in.facebook.com/')}`;

  return <div className={classes.bookCard}>
    <div className={classes.bookImageWrapper}><img className={classes.bookImage} src={book.frontThumbURL} alt="" /></div>
    <div className={classes.achievementBookCardContainer}>
      <div className={classes.bookDetailsWrapper}>
        <div className={classes.bookDetails}>
          <h1 className={classes.bookTitle}>{book.title}</h1>
          <p className={classes.publishDate}>Published on: {date[0]}</p>
        </div>
        <div className={classes.linkButtonWrapper}>
          <LinkButton style={{ color: "#6A3089", fontWeight: 'var(--font-weight-medium)' }} onClick={() => setIsSocialMedia(!isSocialMedia)}>View Poster</LinkButton>
          <LinkButton style={{ color: "#6A3089", fontWeight: 'var(--font-weight-medium)' }} onClick={() => viewCertificateHandler(book.id)}>View Certificate</LinkButton>
        </div>
      </div>
      <div className={classes.shareBookButtonWrapper}>
        <div className={classes.shareImage} title='Share' onClick={() => setIsSocialMedia(!isSocialMedia)}>
          <img className={classes.shareIcon} src={shareIcon} alt="Share Icon" />
        </div>
        <div className={classes.shareImage} title="Download certificate" onClick={() => downloadCertificateHandler(book.id, book.title)}>
          <img className={classes.downloadIcon} src={downloadIcon} alt="Download Icon" />
        </div>
      </div>
    </div>
    {
      isSocialMedia && <ModalDialog onClose={() => setIsSocialMedia(false)} className={classes.modalDialogue} content={<>
        <div className={classes.dialogue}>
          <h1 className={classes.modalHeading}>Share with your friends</h1>
          <div className={classes.dialogueContainer}>
            <div className={classes.bookDisplayCard}>
              <img src={`/designer/books/poster/${book.id}`} alt="" />
            </div>
            <div className={classes.sharingContainer}>
              <div className={classes.sharingLinks}>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${faceBookShare}`}
                  target='_blank'
                  className={classes.socialLinks}
                >
                  <img className={classes.socialMediaIcon} loading="lazy" src={faceBook} alt="facebook" />
                  {" "} <span className={classes.hoverUnderlineAnimation}>Facebook</span>
                </a>
                <a href={`https://api.whatsapp.com/send?text=${shareUrl}%0A%0APreview%2C%20Like%2C%20Share%20%26%20Buy%20my%20new%20book%20%0A%0AProud%20Author%20at%20Bookalooza%0AWrite%2FPublish%2FEarn`}
                  target='_blank'
                  className={classes.socialLinks}
                >
                  <img className={classes.socialMediaIcon} src={whatsapp} alt="whatsapp" />
                  {" "}<span className={classes.hoverUnderlineAnimation}>WhatsApp</span>
                </a>
                <a href={`https://telegram.me/share/url?url=${shareUrl}&text=%0A%0APreview%2C%20Like%2C%20Share%20%26%20Buy%20my%20new%20book%20%0A%0AProud%20Author%20at%20Bookalooza%0AWrite%2FPublish%2FEarn`}
                  target='_blank'
                  className={classes.socialLinks}
                >
                  <img className={classes.socialMediaIcon} src={telegram} alt="telegram" />
                  {" "}<span className={classes.hoverUnderlineAnimation}>Telegram</span>
                </a>
                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
                  target='_blank'
                  className={classes.socialLinks}
                >
                  <img className={classes.socialMediaIcon} src={linkedIn} alt="linkedIn" />
                  {" "}<span className={classes.hoverUnderlineAnimation}>LinkedIn</span>
                </a>
              </div>
              <div className={classes.copyLinkContainer}>
                <label htmlFor="link">Copy link</label>
                <div id='link' className={classes.linkWrapper}>
                  <div className={classes.link}>{`${shareUrl}`}</div>
                  {copyUrl ? <span className={classes.copied}>Copied</span> : <button className={classes.copyLinkBtn}
                    onClick={() => {
                      Utils.copyToClipboard(`${shareUrl}`);
                      setCopyUrl(prev => prev = true);
                      setTimeout(e => setCopyUrl(prev => prev = false), 5000);
                    }}
                  >
                    <img src={copyIcon} alt="" />
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
      />
    }
  </div>
};

export default MyAchievements;